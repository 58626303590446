import { getToken } from '@/utils/auth/auth-storage'
// import { http } from '@/utils/http/index'

// 组件支持的文件格式
export const supportFileTypes = ['doc', 'docx']

const wordUrl = '/we/wordeditorframe.aspx?WOPISrc='

/**
 * 根据文件id 生成word文件的在线预览地址
 * @param {文件id} fileId
 */
// export const buildImageOrPefUrl = async (fileId, isPdf) => {
//   const response = await downloadPreviewFile(fileId)
//   // 构造文件类型信息
//   const defaultType = isPdf ? { type: 'application/pdf' } : {}
//   // 生产
//   return window.URL.createObjectURL(new Blob([response.data], defaultType))
// }

// export const downloadPreviewFile = (params) => {
//   return http({
//     method: 'get',
//     url: `${process.env.VUE_APP_FILE_URL}/api/file/file/view?keyId=${params}`,
//     params,
//     responseType: 'blob'
//   })
// }

/**
 * 创建在线预览地址
 * @param {*} oosUrl
 * @param {*} fileUrl
 * @param {*} fileId
 * @param {*} fileType
 * @returns
 */
export const createOosUrl = (oosUrl, fileUrl, fileType, fileId) => {
  console.log(oosUrl, 'oosUrl')
  console.log(fileUrl, 'fileUrl')
  if (fileUrl.includes('https')) {
    fileUrl = fileUrl.replace('https', 'http')
  }
  // 该位置拼接url
  return oosUrl + wordUrl + fileUrl + '/wopi/files/' + fileId + '?edit&access_token=' + getToken()
}
// https://officeserver.cdyfsz.com/we/wordeditorframe.aspx?WOPISrc=http://wsffile.api.cdyfsz.com/wopi/files/2205241446165320001?edit&access_token=6939821f9621460c8f91d65f7f9eed4b
// https://officeserver.cdyfsz.com/we/wordeditorframe.aspx?WOPISrc=http://wsf2file.api.cdyfsz.com/wopi/files/2206281627562280001?edit&access_token=71ada91f710d412d9da1ffb20dfd5821
