<template>
<div class="newsCover">
  <iframe  width="100%" height="100%" style="flex:1" :src="previewUrl" frameborder="0" id='Iframe' />
</div>
</template>

<script>
import {
  supportFileTypes,
  // buildImageOrPefUrl,
  createOosUrl
  // supportImagesFileType
} from './online-edit'
export default {
  name: 'onlineEdit',
  props: {
    // 文件类型
    fileType: {
      type: String,
      default: 'doc' || 'docx'
    },
    // 文件Id
    fileId: {
      type: String,
      default: ''
    },
    // 在线预览服务地址
    oosUrl: {
      type: String,
      default: process.env.VUE_APP_ONLINE_FILE
    },
    // 文件服务地址
    fileUrl: {
      type: String,
      default: process.env.VUE_APP_FILE_URL
    },
    // 重新构建路由
    count: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      previewUrl: '',
      isNeedRelease: false,
      imgWidth: '',
      imgHeight: ''
    }
  },
  watch: {
    count: {
      handler () {
        this.clearMemory()
        this.initPreview()
        this.$nextTick(() => {
          setTimeout(() => {
            console.log(document.getElementById('Iframe').contentWindow.document.getElementsByTagName('embed')
            )
          }, 2000)
        })
      }
    }
  },
  methods: {
    async initPreview () {
      this.clearMemory()
      const { url, isNeedRelease } = await this.buildUrl()
      this.previewUrl = url
      console.log(this.previewUrl, this.isOpen, '2222')
      this.isNeedRelease = isNeedRelease
    },
    /*
    在每次调用 createObjectURL() 方法时，都会创建一个新的 URL 对象，
    即使你已经用相同的对象作为参数创建过。当不再需要这些 URL 对象时，
    每个对象必须通过调用 URL.revokeObjectURL() 方法来释放。
    浏览器在 document 卸载的时候，会自动释放它们，
    但是为了获得最佳性能和内存使用状况，你应该在安全的时机主动释放掉它们。
    */
    clearMemory () {
      if (this.isNeedRelease && this.url) {
        window.URL.revokeObjectURL(this.url)
      }
    },
    async buildUrl () {
      const { fileId, oosUrl, fileUrl } = this
      let { fileType } = this
      console.log(fileId, 'fileId')
      if (!fileId || typeof fileId !== 'string') {
        this.warning(`文件id参数有误：[${fileId}]`)
        throw new Error(`文件id参数有误：[${fileId}]`)
      }
      // 文件后缀大小写不一致 全部转为小写
      if (fileType) {
        fileType = fileType.toLowerCase()
      }
      if (!fileType || !supportFileTypes.includes(fileType)) {
        this.warning(`文件类型有误：[${fileType}]`)
        throw new Error(`文件类型有误：[${fileType}]`)
      }

      let url = ''
      const isNeedRelease = false

      // url 参数

      url = createOosUrl(oosUrl, fileUrl, fileType, fileId)

      return {
        url,
        isNeedRelease
      }
    }
  },
  beforeDestroy () {
    this.clearMemory()
  }
}
</script>
<style lang="scss" scoped>
.newsCover {
  width: 100%;
   height: 100%;
}
</style>
